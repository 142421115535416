<template>
  <div>
    <div class="hero-wrapper">
      <div class="hero trans-hero">
        <img
            alt="shield"
            class="trans-background"
            src="@/assets/images/trans-banner.png"
        />
        <div class="container">
          <h1 class="text-white">Learning</h1>
          <h3 class="arrow-before text-white pl-30">
            ...<br/>
          </h3>
        </div>
      </div>
    </div>

    <Header />

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <Introduction />
        </b-row>
      </b-container>
    </div>

    <StepsProgress
        :courseProgress="courses"
    />

    <div class="border-gray-200 border-wide-bottom">
      <b-container>
        <div class="border-wide-left border-gray-200 pl-32 pb-8">


          <div v-for="(course, index) in courses" :key="index">
            <div :class="{ completed: course.completed }"
                class="d-flex align-items-center page-num-container pt-80">
              <div class="page-num"></div>
              <h4 class="trans-section-title">{{course.title}}</h4>
            </div>
            <b-row class="trans-section-intro">
              <b-col lg="7">
                <p class="mb-lg-32">
                  {{course.subtitle}}
                </p>
                <p>
                  {{course.description}}
                </p>

                <button
                    class="btn btn-secondary"
                    @click="gotoCourse(course)">Continue
                </button>
              </b-col>
            </b-row>
          </div>

        </div>
      </b-container>
    </div>



    <div class="container">
      <Footer/>
    </div>
  </div>
</template>

<script>
import { http } from "@/services";

import Footer from "@/components/common/questionnaires/questionnaire/Footer";
import Introduction from "./Introduction";
import Header from "./Header";
import StepsProgress from "./StepsProgress";

export default {
  name: "Learning",
  components: {
    StepsProgress,
    Header,
    Introduction,
    Footer,
  },
  created() {
    this.fetchCourses();
  },
  data() {
    return {
      courses: [],
    };
  },
  methods: {
    fetchCourses() {
      http
        .get("course")
        .then((response) => {
          this.courses = response.data;
          this.courses.forEach(c=>{
            c.completed = false;
          });
          console.log('this.courses', this.courses);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gotoCourse(course){
      console.log('course',course);
      this.$router.push({ name: 'Course', params: { id: course.id } })
    },
  },
};
</script>
