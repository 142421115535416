<template>
  <div
      class="hero-sub-bar-two-header border-bottom-gray-200 bg-white w-100 d-flex flex-column justify-content-center">
    <b-container>
      <div class="hero-sub-bar-two-heading d-flex text-16 font-weight-400">
        <div class="d-flex hero-sub-bar-two-heading-stat"><i class="pr-16 text-22 text-mute i-Stopwatch"></i>25
          Minutes
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'Header',
}
</script>
