<template>
  <div class="border-bottom-gray-200 pt-40 pb-20 bg-white">
    <b-container>
      <b-row>

        <b-col class="mb-lg-0"
               lg="5">
          <div class="border-extra-wide-left border-secondary pl-40"><h4 class="mb-30">Progress Summary</h4>

            <ul class="list-unstyled pb-10" v-for="(course, index) in courseProgress"
                  :key="index">

              <li :class="course.completed ?  'success tick-before' : 'cross-before'"
                  class="position-relative pl-30">
                {{ course.title }}
              </li>
            </ul>
          </div>
        </b-col>

        <!-- <b-col class="d-flex align-items-center justify-content-center"
               lg="7">
          <div class="trans-timeline d-flex flex-column flex-sm-row align-items-center justify-content-center flex">

            <div  v-b-popover.hover.top="{title: `Client Profiles`, content: clientProfileProgressPopoverContent, html: true}"
               :class="{'current': !clientProfileComplete}"
               class="trans-timeline-item">
              <span class="trans-timeline-item-tip"></span>
              <span class="page-num">
                  <i v-if="!clientProfileComplete"
                     class="i-Family-Sign text-white"/>
                  <span v-else ></span>
                </span>
            </div>

          </div>
        </b-col> -->
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'StepsProgress',
  props: {
    courseProgress: {
      type: [Array],
      required: true
    },
  },
}
</script>